<header>
  <div class="header-container d-flex">
    <button class="menu-toggle" id="menu-toggle" (click)="toggleMenu()">
      <img [src]="'../../../assets/images/' + (pageTitle ? 'menu-menu.svg' : 'menu.svg') " alt="" />
    </button>

    <div>
      <a routerLink="/home" class="logo">
        <img [src]="'../../../assets/images/' + logoImageName" alt="Madfu" />
      </a>
    </div>

    <ul class="nav-menu" [ngClass]="{ active: menuOpen }">
      <li>
        <a routerLink="/about-us" routerLinkActive="active">{{'about-us' | translate}}</a>
      </li>
<!--      <li>-->
<!--        <a routerLink="/instructions" routerLinkActive="active">التعليمات</a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a routerLink="/islamic-committee" routerLinkActive="active">{{'sharia-committee' | translate}}</a>-->
<!--      </li>-->
      <li>
        <a routerLink="/blogs" routerLinkActive="active">{{'blogs-and-news' | translate}}</a>
      </li>
      <li>
        <a routerLink="/be-partner" routerLinkActive="active">{{'register-as-a-partner' | translate}}</a>
      </li>
    </ul>
  </div>
</header>
