import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BePartnerService } from './services/be-partner.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CategoriesService } from '../home/service/categories.service';
import { partner } from 'src/app/interfaces/category';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  deviceInfo: any;
  macOS = 'mac';
  iosOS = 'ios';
  discoverLink = 'https://app.adjust.com/1fz1h1en';
  bePartnerForm: any;
  captchaResponse: any;
  storePartners: partner[] = [];
  onlinePartners: partner[] = [];
  growthData: any;
  currentLang = localStorage.getItem('lang');
  loading = false;

  customOptionsSlider: any = {
    loop: true,
    margin: 20,
    nav: true,
    autoplay: false,
    rtl: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  getStorePartnersData() {
    this.categoriesService.getStorePartners().subscribe((response: any) => {
      this.storePartners = response;
    });
  }
  getOnlinePartnersData() {
    this.categoriesService.getOnlinePartners().subscribe((response: any) => {
      this.onlinePartners = response;
    });
  }

  initForm() {
    this.bePartnerForm = new FormGroup({
      Area: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Name: new FormControl('', [Validators.required]),
      PhoneNumber: new FormControl('', [
        Validators.required,
        this.numericValidator,
      ]),
      StoreName: new FormControl('', [Validators.required]),
      TRN: new FormControl('', [Validators.required]),
      token: new FormControl(''),
    });
  }

  numericValidator(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(phoneNumber)) {
      return null;
    }
    return { invalidPhone: true };
  }

  constructor(
    private bePartnerService: BePartnerService,
    private deviceService: DeviceDetectorService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toastr: ToastrService,
    private categoriesService: CategoriesService
  ) {}

  bePartnerFormMethod() {
    this.loading = true;

    this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
      console.log(`Token [${token}] generated`);
      this.bePartnerForm.patchValue({
        token: token,
      });
      setTimeout(() => {
        this.bePartnerService.bePartner(this.bePartnerForm.value).subscribe({
          next: () => {
            this.bePartnerForm.reset();
            this.toastr.success('', 'تم التسجيل بنجاح');
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
      }, 0);
    });
  }

  getGrowthTogetherData() {
    this.categoriesService.getGrowthData().subscribe((response: any) => {
      this.growthData = response;
    });
  }

  ngOnInit(): void {
    this.initForm();
    setTimeout(() => {
      document.getElementById('defaultOpen')!.click();
    }, 500);

    this.getStorePartnersData();
    this.getOnlinePartnersData();
    this.getGrowthTogetherData();
  }

  onCaptchaResolved(captchaResponse: any) {
    this.captchaResponse = captchaResponse;
    console.log(`Resolved captcha with response: ${this.captchaResponse}`);
  }

  openItem(evt: MouseEvent, itemName: string): void {
    // Declare all variables
    let i: number;
    let tabcontent: HTMLCollectionOf<Element>;
    let tablinks: HTMLCollectionOf<Element>;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(itemName)!.style.display = 'block';
    (evt.currentTarget as HTMLElement).className += ' active';
  }
}
