import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BePartnerService {

  constructor(private http: HttpClient) { }


  bePartner(data:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    };


    let url = `https://api.madfu.com.sa/Mail/send`;
    return this.http.post(url, data,options);
  }
  


}
