<div class="contact-us-widget" *ngIf="pageTitle != 'Complaints'">
  <div class="email">
    <a href="mailto:{{ footerData?.email }}">
      <img src="../../../assets/images/email.svg" alt="Email Icon" />
      <p>{{ footerData?.email }}</p>
    </a>
  </div>
  <div class="divider"></div>
  <div class="phone">
    <a href="tel:{{ footerData?.phone }}">
      <img src="../../../assets/images/phone.svg" alt="Phone Icon" />
      <p>{{ footerData?.phone }}</p>
    </a>
  </div>
  <div class="divider"></div>
  <div class="phone">
    <a href="https://wa.me/{{ footerData?.whatsapp }}">
      <img src="../../../assets/images/whatsapp.svg" alt="WhatsApp Icon" />
      <p>{{ footerData?.whatsapp }}</p>
    </a>
  </div>
</div>

<footer>
  <div class="">
    <div class="d-flex footer-widget">
      <div class="item logo-widget">
        <a routerLink="/home" class="logo">
          <img src="../../../assets/images/footer-logo.svg" alt="" />
        </a>
        <p class="address">{{ currentLang == 'ar' ? footerData?.addressAr :  footerData?.addressEn  }}</p>
        <ul>
          <li>
            <a target="_blank" href="https://www.instagram.com/enjoy_madfu/">
              <img src="../../../assets/images/instagram.svg" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://x.com/enjoy_madfu">
              <img src="../../../assets/images/twitter.svg" alt="" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/madfu-limited"
            >
              <img src="../../../assets/images/linkedin.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>

      <ul class="item listed-items">
        <li> {{'get-to-know-us' | translate}}</li>

        <li><a routerLink="/blogs"> {{'our-news' | translate}}</a></li>
<!--        <li><a routerLink="/islamic-committee">{{'sharia-committee' | translate}}</a></li>-->
        <li><a routerLink="/privacy">{{'privacy-policy' | translate}}</a></li>
        <li><a routerLink="/terms-and-conditions">{{'terms-and-conditions' | translate}}</a></li>
      </ul>



      <ul class="item listed-items">
        <li>{{'business' | translate}}</li>
        <li><a routerLink="/be-partner">{{'join-our-partners' | translate}}</a></li>
        <li><a routerLink="/">{{'Financial-Statements' | translate}}</a></li>
      </ul>

      <ul class="item listed-items">
        <li>{{'your-voice-is-heard' | translate}}</li>
        <li><a routerLink="/faq">{{'help-center' | translate}}</a></li>
        <li><a routerLink="/faq">{{'FAQ' | translate}}</a></li>
        <li><a href="../../../assets/Consumer_Protection.pdf" target="_blank">{{'Consumer-Protection' | translate}}</a></li>
        <li><a routerLink="/complaints">{{ 'COMPLAINTS_CENTER' | translate }}</a></li>
      </ul>




      <div class="item get-app-widget">
        <p>{{'download-the-app-now' | translate}}</p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268"
            >
              <img src="../../../assets/images/app-store.svg" alt="" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://app.adjust.com/1fz1h1en"
            >
              <img src="../../../assets/images/play-store.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex footer-copyright d-flex">
      <p class="footer-copyright-content-1">
        {{'all-rights-reserved' | translate}}
      </p>

      <div class="footer-copyright-content-2">
        <p>
          {{ currentLang == 'ar' ? footerData?.copyRightTextAr :  footerData?.copyRightTextEn  }}
        </p>
      </div>

      <div class="lang-widget">
        <select name="langs" id="langs" (change)="switchLang($event)" [(ngModel)]="currentLang" >
          <option value="ar">Arabic</option>
          <option value="en">English</option>
        </select>
        <img src="../../../assets/images/lang.svg" alt="" />
     </div>
    </div>
  </div>
</footer>
