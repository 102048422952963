import { Component, OnInit } from '@angular/core';
import { ChangeLangService } from './services/change-lang/change-lang.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'landing-page';
  currentPageName: string = '';
  currentLang = localStorage.getItem('lang') || "ar";
  constructor(
    private changeLang : ChangeLangService,
    private router: Router
    ){
    this.changeLang.changeLangage(this.currentLang , 'appComponent');
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updatePageName(this.router.url);
      }
    });
  }
  updatePageName(url: string) {
    switch (url) {
      case '/home':
        this.currentPageName = 'Home';
        break;
      case '/complaints':
          this.currentPageName = 'Complaints';
          break;
      default:
        this.currentPageName = '';
        break;
    }
  }



}
