<div class="cover-widget">
    <div class="container">
      <h1 class="text-center">{{ 'FAQ' | translate }}</h1>
    </div>
</div>
<div class="widget-data" *ngIf="currentLang == 'ar'">
    <div class="container">
      <div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
        <h2 style="font-size: 33px;">الأسئلة العامة</h2>
    </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ماهو مدفوع؟</h5>
    <p style="font-size: 16px;">يوفر تطبيق مدفوع وسيلة آمنة وميسّرة لخدمات الدفع الآجل تخدم قطاعات الترفيه والتغذية، حيث يقدم التطبيق خيار سداد الفواتير على دفعات متعددة بدون فوائد أو رسوم.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">أين يعمل تطبيق مدفوع؟</h5>
    <p style="font-size: 16px;">يعمل مدفوع في المملكة العربية السعودية، وهو متوفر في جميع المتاجر المشاركة والمعروضة في تطبيق مدفوع.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ما هي المتاجر المشاركة في مدفوع؟</h5>
    <p style="font-size: 16px;">تنمو قائمة المتاجر المشاركة مع مدفوع بشكل مستمر، ولذلك ننصح بتحميل التطبيق للاطلاع على القائمة المحدثة بالمتاجر المشاركة.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يوفر مدفوع خدمات التوصيل من المتاجر؟</h5>
    <p style="font-size: 16px;">يتبنى مدفوع نمط حياة متوازن يتضمن مزاولة الأنشطة الاجتماعية وقضاء أوقات ممتعة خارج المنزل، ولذلك تقتصر خدمات مدفوع على تمويل التجارب الحية ولا يوفر خدمات التوصيل من المتاجر حيث لا يدعم شراء المنتجات عبر الانترنت.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل خدمة مدفوع مرخصة؟</h5>
    <p style="font-size: 16px;">نعم، شركة مدفوع لخدمات الدفع الآجل مصرحة من البنك المركزي السعودي (ساما)</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل تطبيق مدفوع متوافق مع الشريعة الإسلامية؟</h5>
    <p style="font-size: 16px;">جميع الخدمات المقدمة من مدفوع تخضع لمراجعة لجنة شرعية متخصصة لضمان الالتزام بضوابط الشريعة الإسلامية.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يمكنني التواصل مع فريق مدفوع؟</h5>
    <p style="font-size: 16px;">يسعد مدفوع بخدمتكم ويسرنا تواصلكم عن طريق المحادثة المباشرة عن طريق البريد الالكتروني <strong><a href="mailto:contact@madfu.com.sa" style="color: black;">contact@madfu.com.sa</a></strong> أو الاتصال على الرقم الموحد 8001111316</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
        <h2 style="font-size: 33px;">إدارة الحساب</h2>
    </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يمكنني التسجيل في مدفوع ؟</h5>
    <p style="font-size: 16px;">يمكن التسجيل في مدفوع عن طريق تحميل التطبيق المتوفر بجميع متاجر التطبيقات لنظامي آبل وأندرويد.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ما هي الأجهزة الإلكترونية التي يدعمها مدفوع؟</h5>
    <p style="font-size: 16px;">يمكن تحميل التطبيق على جميع الهواتف النقالة والاجهزة اللوحية التي تعمل على أنظمة التشغيل آبل وآندرويد.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يمكنني إنشاء أكثر من حساب في مدفوع؟</h5>
    <p style="font-size: 16px;">يرتبط حساب مدفوع برقم الهوية أو رقم الإقامة الخاصة بالمستخدم، لذلك يمكن لكل مستخدم إنشاء حساب واحد فقط.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يمكنني تغيير رقم هاتفي المسجل في مدفوع؟</h5>
    <p style="font-size: 16px;">نعم، يمكن تغيير رقم الجوال المسجل في مدفوع عن طريق التواصل مع خدمة العملاء.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يمكنني حذف حسابي في مدفوع؟</h5>
    <p style="font-size: 16px;">بإمكانك حذف حسابك في مدفوع عن طريق التواصل مع خدمة العملاء على الرقم 8001111316</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يتم حذف جميع بياناتي بعد حذف الحساب؟</h5>
    <p style="font-size: 16px;">تقوم مدفوع بحذف بيانات المستخدمين باستثناء ما تتطلبه تعليمات البنك المركزي السعودي (ساما).</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يمكنني استخدام مدفوع مرة أخرى بعد حذف حسابي؟</h5>
    <p style="font-size: 16px;">يمكنك استخدام مدفوع مرة أخرى بعد حذف الحساب عن طريق التسجيل في مدفوع مرة أخرى .</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
        <h2 style="font-size: 33px;">عمليات الدفع</h2>
    </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ما هي آلية عمل التطبيق؟</h5>
    <p style="font-size: 16px;">لسداد فواتيرك عن طريق مدفوع، اضغط على خيار &ldquo;مسح الفاتورة&rdquo; المتوفر في شاشة التطبيق الرئيسية. باستخدام كاميرا الهاتف الجوال، امسح فاتورة الشراء المقدمة من أي من المتاجر المشاركة. سيتعرف التطبيق تلقائياً على تفاصيل الفاتورة وستظهر لك خيارات الدفع المتاحة.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ما هي خيارات الدفع في مدفوع؟</h5>
    <p style="font-size: 16px;">يمكنك تسديد الفاتورة على دفعات متعددة وبدون فوائد، بحيث يتم سداد القسط الأول عند الشراء.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ماهو الحد الأعلى للعمليات الشرائية في مدفوع؟</h5>
    <p style="font-size: 16px;">عند الاستفادة من خدمة الدفع الآجل والشراء على دفعات، فيعتمد الحد الأعلى للعمليات الشرائية على المبلغ المتاح للعميل بناءً على سجله الائتماني والمبالغ المستحقة. يمكنك معرفة الحد الائتماني المتاح عن طريق صفحة الملف الشخصي في تطبيق مدفوع.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يمكنني سداد الدفعات المستحقة؟</h5>
    <p style="font-size: 16px;">لسداد المبالغ المستحقة عن طريق التطبيق، يرجى اتباع الآتي:<br>الدخول على &ldquo;طلباتي&rdquo; من الشاشة الرئيسية &gt; دفعاتي القادمة &gt; تفاصيل &gt; ستظهر الدفعات المستحقة ضع علامة الصح على الدفعة المختارة &gt; أضغط على دفع مبلغ &gt; أختر طريقة الدفع المناسبة.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">ما هي طرق الدفع المتاحة لسداد المبالغ المستحقة في مدفوع؟</h5>
    <p style="font-size: 16px;">يمكنك سداد المبالغ المستحقة عن طريق التطبيق باستخدام أحد وسائل الدفع الالكترونية التالية: بطاقة مدى- فيزا - ابل باي</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل أستطيع الشراء مع وجود مستحقات متأخرة لم أدفعها بعد؟</h5>
    <p style="font-size: 16px;">في حال وجود مستحقات متأخرة فلن تتمكن من الدفع الآجل والشراء على دفعات.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يمكن سداد أكثر من دفعة في نفس الوقت؟</h5>
    <p style="font-size: 16px;">يتيح مدفوع عملية السداد المبكر لبعض أو كل المبالغ المستحقة بحسب رغبة العميل.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل أستطيع رفع حدي الإئتماني؟</h5>
    <p style="font-size: 16px;">يتم تحديد الحد الإئتماني للعملاء بشكل آلي بناء على سجلهم الائتماني ومدى التزامهم بالدفع في الوقت المحدد.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">لماذا تم رفض عملية الدفع ؟</h5>
    <p style="font-size: 16px;">1- التأكد من إدخال بيانات البطاقة بشكل صحيح .<br>2- التأكد من صلاحية البطاقة .<br>3- التأكد من وجود رصيد كافي لعملية السداد .<br>4- التأكد من عدم تجاوز الحد الإئتماني للبطاقة.<br>5- التواصل مع البنك الخاص بك.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">هل يتم تحصيل فوائد عند التأخر في السداد؟</h5>
    <p style="font-size: 16px;">لا يتقاضى مدفوع أي فوائد عند التأخر في السداد.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يمكنني استرداد قيمة الفاتورة؟</h5>
    <p style="font-size: 16px;">خدمات الترفيه والتغذية بطبيعتها غير مستردة، لذلك لا يمكن إسترجاع المبلغ بعد عملية الشراء.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
        <h2 style="font-size: 33px;">شركاء مدفوع</h2>
    </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يمكنني تسجيل متجري كشريك ؟</h5>
    <p style="font-size: 16px;">يسعدنا انضمامكم إلى أسرة المتاجر المشاركة عن طريق التواصل على البريد الالكتروني <strong><a href="mailto:sales@madfu.com.sa" style="color: black;">sales@madfu.com.sa</a></strong></p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">متى تستلم المتاجر مبالغها المستحقة؟</h5>
    <p style="font-size: 16px;">يتم إيداع المبالغ المستحقة في حساب التاجر بشكل أسبوعي وبإجمالي أربع مرات بالشهر، ويمكن للتاجر اختيار يوم الأسبوع المناسب لإيداع المبالغ المستحقة.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف أحصل على فواتيري؟</h5>
    <p style="font-size: 16px;">يوفر مدفوع منصة متكاملة لدعم التجار المشاركين تتضمن قائمة بالطلبات والفواتير، بحيث يتمكن التاجر من الاطلاع على حالة المبيعات والمبالغ التي تم تحويلها في أي وقت.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">كيف يتم تدريب الموظفين على استخدام تطبيق مدفوع؟</h5>
    <p style="font-size: 16px;">يوفر مدفوع التدريب اللازم لموظفي المتاجر المشاركة قبل إطلاق المتجر على تطبيق مدفوع.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
    <h5 style="font-size: 20px;">لماذا تم رفض طلب اشتراكي مع مدفوع؟</h5>
    <p style="font-size: 16px;">يستهدف مدفوع قطاع الترفية والتغذية، لذلك يتعذر تسجيل المتاجر التي تعمل في قطاعات أخرى. في حال كان المتجر ضمن القائمة المستهدفة، نأمل تواصلكم مع خدمة العملاء عن طريق 8001111316</p>
</div>
    </div>
</div>

<div class="widget-data" *ngIf="currentLang == 'en'">
  <div class="container">
    <div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
      <h2 style="font-size: 33px;">General questions</h2>
  </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">What is paid?</h5>
  <p style="font-size: 16px;">The Paid app provides a safe and convenient way for postpaid services serving the entertainment and food sectors, as the app offers the option to pay bills in multiple installments without interest or fees.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Where does a paid app work?</h5>
  <p style="font-size: 16px;">Paid works in Saudi Arabia, and is available in all participating stores and displayed in the Paid app.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Which stores participate in Paid?</h5>
  <p style="font-size: 16px;">The list of participating stores with Paid is constantly growing, so we recommend downloading the app to see the updated list of participating stores.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Does Paid provide delivery services from stores?</h5>
  <p style="font-size: 16px;">Madfoo adopts a balanced lifestyle that includes social activities and spending fun times outside the home. Therefore, Madfoo&rsquo;s services are limited to financing live experiences and do not provide delivery services from stores, as it does not support purchasing products online.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Is the paid service licensed?</h5>
  <p style="font-size: 16px;">Yes, Madfoo Postpaid Services Company is authorized by the Saudi Central Bank (SAMA).</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Is a paid app Sharia compliant?</h5>
  <p style="font-size: 16px;">All services provided by Madfoo are subject to review by a specialized Sharia committee to ensure compliance with Islamic Sharia regulations.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How can I contact a paid team?</h5>
  <p style="font-size: 16px;">Madfu is pleased to serve you and we are pleased to communicate with you via direct chat via email <strong><a href="mailto:contact@madfu.com.sa" style="color: black;">contact@madfu.com.sa</a></strong> or by calling the unified number 8001111316</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
      <h2 style="font-size: 33px;">Account management</h2>
  </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How can I register for paid?</h5>
  <p style="font-size: 16px;">You can register for Mudfoo by downloading the application available in all app stores for Apple and Android systems.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">What electronic devices does Paid support?</h5>
  <p style="font-size: 16px;">The application can be downloaded on all mobile phones and tablets that run on Apple and Android operating systems.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I create more than one account in Paid?</h5>
  <p style="font-size: 16px;">A paid account is linked to the user&apos;s ID number or residence number, so each user can create only one account.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I change my paid phone number?</h5>
  <p style="font-size: 16px;">Yes, the mobile number registered in Paid can be changed by contacting customer service.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I delete my paid account?</h5>
  <p style="font-size: 16px;">You can delete your paid account by contacting customer service at 8001111316</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Will all my data be deleted after deleting the account?</h5>
  <p style="font-size: 16px;">Madfa deletes user data except as required by the instructions of the Saudi Central Bank (SAMA).</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I use Paid again after deleting my account?</h5>
  <p style="font-size: 16px;">You can use Paid again after deleting the account by registering in Paid again.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
      <h2 style="font-size: 33px;">Payments</h2>
  </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How does the application work?</h5>
  <p style="font-size: 16px;">To pay your bills via Madfoo, tap on the &ldquo;Scan Bill&rdquo; option available on the app&rsquo;s home screen. Using your mobile phone&rsquo;s camera, scan the purchase bill provided by any of the participating stores. The app will automatically recognize the bill details and show you the available payment options.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">What are the payment options in Paid?</h5>
  <p style="font-size: 16px;">You can pay the bill in multiple installments without interest, with the first installment being paid upon purchase.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">What is the maximum limit for purchases in Madfoo?</h5>
  <p style="font-size: 16px;">When using the deferred payment service and purchasing in installments, the maximum limit for purchases depends on the amount available to the customer based on his credit history and the amounts due. You can find out the available credit limit through the profile page in the Paid application.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How can I make payments?</h5>
  <p style="font-size: 16px;">To pay the amounts due through the application, please follow the following:<br>Go to &ldquo;My Orders&rdquo; from the main screen &gt; My Upcoming Payments &gt; Details &gt; The due payments will appear. Check the selected payment &gt; Click on Pay Amount &gt; Choose the appropriate payment method.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">What payment methods are available to pay the amounts due in Madfoo?</h5>
  <p style="font-size: 16px;">You can pay the amounts due through the application using one of the following electronic payment methods: Mada Card - Visa - Apple Pay</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I purchase if I have outstanding balances that I have not yet paid?</h5>
  <p style="font-size: 16px;">If there are any overdue payments, you will not be able to pay on credit and purchase in installments.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I make more than one payment at the same time?</h5>
  <p style="font-size: 16px;">Paid allows early payment of some or all of the amounts due, according to the customer&rsquo;s desire.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Can I increase my credit limit?</h5>
  <p style="font-size: 16px;">The credit limit of customers is determined automatically based on their credit history and their commitment to paying on time.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Why was the payment declined?</h5>
  <p style="font-size: 16px;">1- Make sure that the card data is entered correctly.<br>2- Make sure that the card is valid.<br>3- Make sure that there is sufficient balance for the payment process.<br>4- Make sure that the card&apos;s credit limit is not exceeded.<br>5- Contact your bank.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Are there any late payment interest charges?</h5>
  <p style="font-size: 16px;">No interest is charged for late payment.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How can I get a refund for my invoice?</h5>
  <p style="font-size: 16px;">Entertainment and food services are non-refundable by nature, so no refunds can be made after purchase.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;"><strong>
      <h2 style="font-size: 33px;">Paid Partners</h2>
  </strong></div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How can I register my store as a partner?</h5>
  <p style="font-size: 16px;">We are pleased to have you join the family of participating stores by contacting us via email <strong><a href="mailto:sales@madfu.com.sa" style="color: black;">sales@madfu.com.sa</a></strong></p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">When do stores receive their payments?</h5>
  <p style="font-size: 16px;">The amounts due are deposited into the merchant&rsquo;s account on a weekly basis and a total of four times per month, and the merchant can choose the appropriate day of the week to deposit the amounts due.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How do I get my invoices?</h5>
  <p style="font-size: 16px;">Madfoo provides an integrated platform to support participating merchants, including a list of orders and invoices, so that the merchant can view the status of sales and amounts transferred at any time.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">How are employees trained to use a paid app?</h5>
  <p style="font-size: 16px;">Paid provides the necessary training to participating store employees before launching the store on Paid App.</p>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <div><br></div>
</div>
<div style="text-align: start;color: rgb(0, 0, 0);font-size: medium;">
  <h5 style="font-size: 20px;">Why was my paid subscription application rejected?</h5>
  <p style="font-size: 16px;">Paid targets the entertainment and food sector, so stores operating in other sectors cannot be registered. If the store is on the targeted list, we hope you will contact customer service via 8001111316</p>
</div>
  </div>
</div>
