<div class="be-partner-cover">
  <div class="container">
    <div class="be-partner-cover-widget d-flex">
      <div class="be-partner-data">
        <h1>{{ "we-introduce-you-and-increase-your-sales" | translate }}</h1>
        <p>
          {{ "we-introduce-you-and-increase-your-sales-desc" | translate }}
        </p>
        <button class="be-partner-button">
          <img src="../../../assets/images/send-square.svg" alt="send square" />
          {{ "register-as-a-partner" | translate }}
        </button>
      </div>
      <img
        class="be-partner"
        [src]="'../../../assets/images/be-parnter-img-' + currentLang + '.svg'"
        alt="be partner"
      />
    </div>
  </div>
</div>

<div class="growth-together">
  <div class="container">
    <div class="growth-together-widget">
      <h2>{{ "we-grow-together" | translate }}</h2>
      <p>
        {{
          "madfu-Your-best-financial-partner-for-your-store-payments"
            | translate
        }}
      </p>
      <owl-carousel-o [options]="customOptionsSlider">
        <ng-template carouselSlide *ngFor="let item of growthData">
          <div class="growth-together-widget-item">
            <img [src]="'../../../assets/images/' + item.image" alt="" />
            <div>
              <h4>{{ currentLang == "ar" ? item.titleAr : item.titleEn }}</h4>
              <p>
                {{ currentLang == "ar" ? item.descAr : item.descEn }}
              </p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</div>

<div class="our-main-partners-be-partner-page d-flex">
  <div class="container">
    <div class="our-main-partners-container d-flex">
      <div class="main-tabs">
        <h2 class="title">{{ "our-partners" | translate }}</h2>
        <p class="sub-title">
          {{ "our-partners-desc" | translate }}
        </p>
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
            {{ "store" | translate }}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{ "online" | translate }}
          </button>
        </div>
        <ul>
          <li>{{ "make-it-easier" | translate }}</li>
          <li>{{ "reach-a-larger" | translate }}</li>
          <li>
            {{ "increase-your-average" | translate }}
          </li>
        </ul>
      </div>
      <div class="tabs-content-widgets">
        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners | slice : 0 : 4">
              <p>{{ currentLang == "ar" ? store.titleAr : store.titleEn }}</p>
              <img
                [src]="'../../../assets/images/store/' + store.image"
                alt=""
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners | slice : 0 : 4">
              <p>{{ currentLang == "ar" ? store.titleAr : store.titleEn }}</p>
              <img
                [src]="'../../../assets/images/online/' + store.image"
                alt=""
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="join-us">
  <div class="container">
    <div class="join-us-widget d-flex">
      <div class="form">
        <h3>{{ "share-our-success-and-join-our-partners" | translate }}</h3>
        <p>{{ "ready-to-increase-your-sales-register-now" | translate }}</p>
        <form [formGroup]="bePartnerForm" (ngSubmit)="bePartnerFormMethod()">
          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'name' | translate"
                formControlName="Name"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('Name').invalid &&
                    bePartnerForm.get('Name').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('Name').invalid &&
                    bePartnerForm.get('Name').touched
                  "
                  class="error"
                >
                  {{ "name-is-required" | translate }}
                </div>
              </div>
            </div>
            <div class="form-widget-content">
              <input
                type="email"
                [placeholder]="'email' | translate"
                formControlName="Email"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('Email').invalid &&
                    bePartnerForm.get('Email').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('Email').invalid &&
                    bePartnerForm.get('Email').touched
                  "
                  class="error"
                >
                  {{ "email-is-required" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="tel"
                class="form-widget-input"
                [placeholder]="'phone' | translate"
                formControlName="PhoneNumber"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('PhoneNumber').invalid &&
                    bePartnerForm.get('PhoneNumber').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('PhoneNumber').invalid &&
                    bePartnerForm.get('PhoneNumber').touched
                  "
                  class="error"
                >
                  <span
                    *ngIf="bePartnerForm.get('PhoneNumber').errors?.required"
                    >{{ "phone-is-required" | translate }}
                  </span>
                  <span
                    *ngIf="
                      bePartnerForm.get('PhoneNumber').errors?.invalidPhone
                    "
                    >{{ "phone2-is-required" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-widget-content">
              <input
                type="text"
                [placeholder]="'store-name' | translate"
                formControlName="StoreName"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('StoreName').invalid &&
                    bePartnerForm.get('StoreName').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('StoreName').invalid &&
                    bePartnerForm.get('StoreName').touched
                  "
                  class="error"
                >
                  {{ "store-name-is-required" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-widget">
            <div class="form-widget-content">
              <input
                type="text"
                class="form-widget-input"
                [placeholder]="'sector' | translate"
                formControlName="Area"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('Area').invalid &&
                    bePartnerForm.get('Area').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('Area').invalid &&
                    bePartnerForm.get('Area').touched
                  "
                  class="error"
                >
                  {{ "sector-is-required" | translate }}
                </div>
              </div>
            </div>
            <div class="form-widget-content">
              <input
                type="text"
                [placeholder]="'commercial-registration-no' | translate"
                formControlName="TRN"
                [ngClass]="{
                  'invalid-input':
                    bePartnerForm.get('TRN').invalid &&
                    bePartnerForm.get('TRN').touched
                }"
              />
              <div class="error-space">
                <div
                  *ngIf="
                    bePartnerForm.get('TRN').invalid &&
                    bePartnerForm.get('TRN').touched
                  "
                  class="error"
                >
                  {{ "commercial-registration-no-is-required" | translate }}
                </div>
              </div>
            </div>
          </div>
          <button
            [disabled]="bePartnerForm.invalid || loading"
            class="be-partner-button"
          >
            <img
              src="../../../assets/images/send-square.svg"
              alt="send square"
            />
            {{ "register-as-a-partner" | translate }}
          </button>
        </form>
      </div>
      <img
        class="be-partner-form-image"
        src="../../../assets/images/be-partner-form.svg"
        alt=""
      />
    </div>
  </div>
</div>
